<template>
  <div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row justify-content-end">
          <div class="col-md-2">
            <v-select label="title" required :searchable="false" v-model="filters.perPage" :options="perPageOptions"
              @input="setPerPageSelected" :clearable="false" />
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <v-select label="title" placeholder="Status" :searchable="false" item-text="title" item-value="code"
                :options="optionsStatus" v-model="filters.status">
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-1 mb-md-0">
              <v-select label="title" placeholder="Forma de pagamento" :searchable="false" item-text="title"
                item-value="code" :options="optionsPaymentMethods" v-model="filters.method">
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mb-1 mb-md-0">
              <v-select :searchable="false" label="title" item-text="title" v-model="filters.origin" item-value="code"
                :options="optionsOrigins" placeholder="Origem da venda" />
            </div>
          </div>

          <div class="col-md-8">
            <div class="form-group mb-1 mb-md-0">
              <v-select placeholder="Curso" label="title" item-text="title" item-value="code" v-model="filters.course"
                :options="optionsCourses" @search="optionsCourses">
                <span slot="no-options">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-1 mb-md-0">
              <flat-pickr v-model="filters.rangeDate" class="form-control" placeholder="Período de venda" :config="{
                mode: 'range',
                altInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                locale: 'pt',
              }" />
            </div>
          </div>

          <div class="col-md-1">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="submitedFilter">
                <b-spinner label="Loading..." small /> Buscando...
              </div>
              <div v-else>
                <feather-icon icon="SearchIcon" class="cursor-pointer cursor" size="16" />
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>

    <b-card no-body>
      <b-table sticky-header :items="dataItems" responsive :fields="fields" show-empty :busy="loading"
        empty-text="Nenhum registro encontrado" class="position-relative">

        <template #cell(total)="{ item }">
          <span class="text-center">
            {{ item.total | toCurrency }}
          </span>
        </template>

        <template #cell(ticket)="{ item }">
          <span class="text-center">
            {{ item.ticket | toCurrency }}
          </span>
        </template>

        <template #cell(product_title)="{ item }">
          {{ item.product_title }}
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner small class="align-middle" style="margin-right: 3px"></b-spinner>
            <strong> carregando...</strong>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable :rows-table="rowsTable" :current-page="filters.currentPage" :per-page="filters.perPage"
        :total-rows="totalRows" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BIcon, BRow, BCol, BFormInput, BSpinner, BForm,
  BTable, BButton, BPagination, BAvatar, BMedia, BTooltip,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import moment from 'moment';

export default {
  components: {
    CustomPaginateTable,
    BCard,
    BIcon,
    flatPickr,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BAvatar,
    BMedia,
    BTooltip,
    vSelect,
    BSpinner,
    BForm,
  },
  data() {
    return {
      rows: 200,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      filters: {
        currentPage: 1,
        perPage: 100,
        rangeDate: `${moment().subtract('1', 'week').format('YYYY-MM-DD')} até ${moment().format('YYYY-MM-DD')}`,
        status: "",
        method: "",
        course: "",
        origin: "",
      },
      loading: false,
      btnSearch: false,
      fields: [
        {
          key: 'course_title',
          label: 'Curso',
          class: 'text-left',
          sortable: false,
        },
        {
          key: 'quantity',
          label: 'Matrículas',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'ticket',
          label: 'Ticket',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'total',
          label: 'Total',
          class: 'text-center',
          sortable: false,
        },
      ],
      dataItems: [],
      submitedFilter: false,
      optionsStatus: [],
      optionsPaymentMethods: [],
      optionsCourses: [],
      optionsOrigins: [],
    };
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
  mounted() {
    this.fetchStatus()
    this.fetchPaymentMethods()
    this.getData()
    this.fetchOrderOrigins();

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:export-table", () => {
      console.log(2)
      this.$swal({
        title: "Exportar Pedidos por Produtos",
        text: "Enviaremos para o seu e-mail e WhatsApp, o link para você baixar o relatório de pedidos por produto.",
        iconHtml: '<i class="bi bi-download"></i>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("Order/export", {
              filters: {                
                status: this.filters.status ? this.filters.status.code : "",
                method: this.filters.method ? this.filters.method.code : "",
                origin: this.filters.origin ? this.filters.origin.code : "",
                course: this.filters.course ? this.filters.course.code : "",                
                rangeDate: this.filters.rangeDate ?? "",
              },
            })
            .then((res) => {
              console.log(res);
            })
            .finally(() => {
              this.submitedFilter = false;
            });
        }
      });
    });
  },
  methods: {
    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
      this.getData();
    },

    updatePage() {
      this.getData();
    },

    async fetchOrderOrigins() {
      this.$store.dispatch("Order/forSelectOrigins").then((res) => {
        this.optionsOrigins = res;
      });
    },

    async fetchCourse(term) {
      this.optionsCourses = [];
      if (term.length > 2) {
        this.optionsCourses = await this.$store.dispatch("Course/fetchSearch", term);
      }
    },

    async fetchStatus() {
      this.$store.dispatch("Order/status").then((res) => {
        this.optionsStatus = res;
      });
    },
    async fetchPaymentMethods() {
      this.$store.dispatch("Order/paymentMethods").then((res) => {
        this.optionsPaymentMethods = res;
      });
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },
    async getData() {
      this.loading = true;

      let filtersSearch = {
        rangeDate: this.filters.rangeDate ?? "",
        status: this.filters.status ? this.filters.status.code : "",
        method: this.filters.method ? this.filters.method.code : "",
        course: this.filters.course ? this.filters.course.code : "",
        origin: this.filters.origin ? this.filters.origin.code : "",
        currentPage: this.filters.currentPage,
        perPage: this.filters.perPage,
      };

      this.$store
        .dispatch("Order/allCourses", filtersSearch)
        .then((orders) => {
          if (orders) {
            this.dataItems = orders.data;
            this.rowsTable = orders.data.length;
            this.totalRows = orders.meta.total;
            this.filters.currentPage = orders.meta.current_page;
            this.loading = false;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
          this.btnSearch = false;

        });
    }
  }
};
</script>
