var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              ref: "formFilter",
              staticClass: "m-0",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c("div", { staticClass: "form-row justify-content-end" }, [
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "title",
                        required: "",
                        searchable: false,
                        options: _vm.perPageOptions,
                        clearable: false,
                      },
                      on: { input: _vm.setPerPageSelected },
                      model: {
                        value: _vm.filters.perPage,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "perPage", $$v)
                        },
                        expression: "filters.perPage",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            placeholder: "Status",
                            searchable: false,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsStatus,
                          },
                          model: {
                            value: _vm.filters.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "status", $$v)
                            },
                            expression: "filters.status",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-1 mb-md-0" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            label: "title",
                            placeholder: "Forma de pagamento",
                            searchable: false,
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsPaymentMethods,
                          },
                          model: {
                            value: _vm.filters.method,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "method", $$v)
                            },
                            expression: "filters.method",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-1 mb-md-0" },
                    [
                      _c("v-select", {
                        attrs: {
                          searchable: false,
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: _vm.optionsOrigins,
                          placeholder: "Origem da venda",
                        },
                        model: {
                          value: _vm.filters.origin,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "origin", $$v)
                          },
                          expression: "filters.origin",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-8" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-1 mb-md-0" },
                    [
                      _c(
                        "v-select",
                        {
                          attrs: {
                            placeholder: "Curso",
                            label: "title",
                            "item-text": "title",
                            "item-value": "code",
                            options: _vm.optionsCourses,
                          },
                          on: { search: _vm.optionsCourses },
                          model: {
                            value: _vm.filters.course,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "course", $$v)
                            },
                            expression: "filters.course",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v(" Nenhum registro encontrado ")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group mb-1 mb-md-0" },
                    [
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Período de venda",
                          config: {
                            mode: "range",
                            altInput: true,
                            dateFormat: "Y-m-d",
                            altFormat: "d/m/Y",
                            locale: "pt",
                          },
                        },
                        model: {
                          value: _vm.filters.rangeDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "rangeDate", $$v)
                          },
                          expression: "filters.rangeDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-1" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm.submitedFilter
                        ? _c(
                            "div",
                            [
                              _c("b-spinner", {
                                attrs: { label: "Loading...", small: "" },
                              }),
                              _vm._v(" Buscando... "),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("feather-icon", {
                                staticClass: "cursor-pointer cursor",
                                attrs: { icon: "SearchIcon", size: "16" },
                              }),
                            ],
                            1
                          ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.fields,
              "show-empty": "",
              busy: _vm.loading,
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(total)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "text-center" }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("toCurrency")(item.total)) + " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(ticket)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "text-center" }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("toCurrency")(item.ticket)) + " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(product_title)",
                fn: function (ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.product_title) + " ")]
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-primary my-2" },
                      [
                        _c("b-spinner", {
                          staticClass: "align-middle",
                          staticStyle: { "margin-right": "3px" },
                          attrs: { small: "" },
                        }),
                        _c("strong", [_vm._v(" carregando...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }